<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des articles" @refresh="loadData" />
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            selectedProduct: {
                Description: ""
            },
            allData: [],
            headerTitle: ["N°", "Designation", "D.L.C", "Stock"],
            headerVal: ["No", "Description", "DLC", "Sales_Inventory"],
            headerTitleModal: ["Mois", "Type", "Quantité", "Montant"],
            headerValModal: ["Month", "Entry_Type", "Sum_Quantity", "Amount"],
            listeDates: [],
            selectedDateModal: null,
            selectedType: null,
            listeTypes: [],
            ItemLedgerEntry: [],
            fields: [{
                label: 'Mois',
                key: 'Month',
                width: '80px',
                sortable: true
            },
            {
                label: 'Type',
                key: 'Entry_Type',
                width: '120px',
                sortable: true
            },
            {
                label: 'Quantité',
                key: 'Sum_Quantity',
                width: '80px',
                sortable: true
            }, {
                label: 'Montant',
                key: 'Amount',
                width: '80px',
                sortable: true
            }
            ],
            rowData: [],
            activeFilter: null,
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "N°",
                        field: "No",
                    },
                    {
                        headerName: "Description",
                        field: "Description",
                    },
                    {
                        headerName: "D.L.C",
                        field: "DLC",
                    },
                    {
                        headerName: "Stock",
                        field: "Sales_Inventory",
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("inventory_by_vendor");
            this.agGrid.rows = response.data.reverse();
            this.showLoading = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
